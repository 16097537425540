.wrapper {
  width: 1200px;
  margin: auto;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    width: auto;
  }
}
