.card-container {
  box-sizing: border-box;
  padding: 12px 24px;
}

.card-inner-container {
  background: #fff;
  border-radius: 7px;
  padding: 24px;
}
