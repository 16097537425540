@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,200;0,300;1,900&family=Gabriela&family=Oswald:wght@600&display=swap');

:root {
  --bg_clr: #ffffc8;
  --accent_clr: #3a3b54;
  --primary_fnt: 'Fredoka', sans-serif;
  --primary_gradient: linear-gradient(to bottom right, #fffff1, #d9ebee);
}

body {
  background: #40eb80;
}

h1, h2, h3 , h4, h5, h6, p, a, li, td {
  font-family: var(--primary_fnt) !important;
  font-weight: 300;
}

.payment-btn {
  width: 320px !important;
  border: none;
  background: #0000ff !important;
  color: #fff !important;
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 7px;
  cursor: pointer;
  
}